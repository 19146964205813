"use client";

import Script from "next/script";
import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";
import Cookies from "js-cookie";

declare global {
  interface Window {
    ATInternet: any;
    OnetrustActiveGroups: any;
  }
}

export const PianoAnalyticsClientScript = (props: { url: string }) => {
  const pathName = usePathname();
  const isInitalRender = useRef(false);

  const sendPianoEvent = () => {
    if (typeof window !== "undefined" && window.ATInternet) {
      const tag = new window.ATInternet.Tracker.Tag();

      tag.page.set({
        name: pathName,
      });

      const oneTrustCookie = Cookies.get("OptanonConsent");
      //check if the user has accepted the onetrust cookies propmt
      if (oneTrustCookie?.includes("C0002")) {
        tag.privacy.setVisitorOptin(); // User accepted the cookies
      } else {
        //ce cas quand l'utilisateur n'a pas accepté
        tag.privacy.setVisitorMode("cnil", "exempt"); // Did not accept the cookies
      }

      tag.dispatch();
    }
  };

  useEffect(() => {
    // the isInitalRender check, will make sure to skip sending the piano event on initial load, because the intial one is handled by the Script onLoad.
    // Afterwards, the useEffect will send the event every time the pathName changes
    if (isInitalRender.current) {
      sendPianoEvent();
    } else {
      isInitalRender.current = true;
    }
  }, [pathName]);

  return (
    <Script
      strategy="lazyOnload"
      src={props.url}
      onLoad={() => {
        sendPianoEvent();
      }}
    />
  );
};
